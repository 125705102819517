<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-specification"
    v-html="title"
  />
  <td v-else class="vuetable-td-component-specification">
    <div class="flex items-center">
      <span class="block lowercase mr-2">{{specification.name}}</span>
      <specification-info class="info-button opacity-0" :properties="specification.properties" :isNullable="specification.isNullable"></specification-info>
    </div>
  </td>
</template>

<script>
  import VuetableFieldMixin from 'vue3-vuetable/src/components/VuetableFieldMixin.vue';
  import SpecificationInfo from '@/components/auth/data_block/SpecificationInfo';

  export default {
    name: 'SpecificationField',
    mixins: [VuetableFieldMixin],
    components: {
      SpecificationInfo,
    },
    computed: {
      specification() {
        const getSpecification = this.rowField.switch?.getSpecification;

        if (typeof getSpecification === 'function') {
          return getSpecification(this.rowData.specificationId) || {};
        }

        return {};
      },
    },
  };
</script>
<style scoped>
  .vuetable-td-component-specification:hover :deep(.info-button) {
    @apply opacity-100;
  }
</style>
