<template>
  <List
    title="shared data keys"
    resource="dataKeys"
    :basePath="basePath"
    :fields="fields"
    :page-size="30"
    ref="list"
    :search="true"
    search-placeholder="Search for a data key by name, description"
    on-row-click="edit"
    data-provider="$raaDataProvider"
    infinity-scroll
    :initial-filter="entityDefaultFilter"
    :filter-classes="{container: 'w-2/3 items-end mb-6', quickFilters: 'w-1/3 mr-6', searchContainer: 'flex-1'}"
  >
    <template v-slot:quickfilter="{applyFilter}">
      <div class="form-col">
        <label>{{entityFilterName}}</label>
        <Dropdown
          :options="entityFilterOptions"
          :value="entityFilterValue"
          @input="v => { applyFilter(mapEntityFilterToQuery(v)); }"
          show-all
          no-clear
        />
      </div>
    </template>
    <template v-slot:inline-actions="actionsScope">
      <button
        v-if="!actionsScope.record.deletedAt"
        class="btn-action-warning focus:outline-none"
        type="button"
        @click.stop="remove(actionsScope.record)"
      >
        <icon class="w-5 h-5 mr-1" name="trash"/>
      </button>
    </template>
  </List>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import Icon from "@/components/ui/Icon";
  import List from "@/components/auth/list/List";
  import SpecificationField from "@/components/auth/list/fields/SpecificationField";
  import Dropdown from "@/components/ui/Dropdown";
  import EntityFilterMixin from "@/components/auth/EntityFilterMixin";

  export default {
    name: "SharedDataKeysList",
    components: {
      Icon,
      List,
      Dropdown,
    },
    mixins: [ModalNavigation, ConfirmationMixin, EntityFilterMixin, NotifyMixin, ValidatorMixin],
    computed: {
      basePath: function () {
        return this.$route.path;
      },
      specificationsMap() {
        return this.specifications.reduce(
          (acc, item) => ({...acc, [item.id]: item }),
          {}
        );
      },
    },
    data: function () {
      return {
        loading: true,
        specifications: [],
        fields: [
          {
            name: 'key',
            title: 'key',
            width: '30%',
            sortField: 'key',
          },
          {
            name: SpecificationField,
            title: 'specification',
            width: '30%',
            switch: {
              getSpecification: (id) => this.specificationsMap[id],
            }
          },
          {
            name: 'description',
            title: 'description',
            width: '32%',
          },
          {
            name: 'inline-actions',
            title: '',
            width: '8%',
          }
        ],
      }
    },
    methods: {
      remove({ id }) {
        this.requestConfirmation({
          confirmationMessage: 'Are you sure you want to delete this data key?',
          confirmBtnText: 'yes',
          cancelBtnText: 'no',
          confirmationType: 'warning'
        })
          .then(confirmed => confirmed
            ? this.$raaDataProvider.delete('dataKeys', {id})
                .then(() => {
                  this.$refs.list.doSearch();
                  this.notifySuccess('shared data key is deleted');
                })
                .catch(error => this.notifyError(error.message))
            : null
          );
      },

      fetchSpecifications() {
        this.$raaDataProvider.getList('specifications')
          .then(({content}) => {
            this.specifications = content;
          })
          .catch((error) => this.notifyError(error.message))
      },
    },
    mounted() {
      Promise.all([
        this.fetchSpecifications(),
      ]);
    },
  }
</script>

<style scoped>

</style>
